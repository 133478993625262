/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/axios@0.21.0/dist/axios.min.js
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.min.js
 * - /npm/fullcalendar@5.4.0/main.min.js
 * - /npm/jquery-replacetext@1.1.0/replacetext.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
